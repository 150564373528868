import type { Readable } from 'svelte/store';

export enum Steps {
  STEP_0 = 0,
  STEP_1 = 1,
  STEP_2 = 2,
  STEP_3 = 3,
  STEP_4 = 4,
  STEP_5 = 5,
  STEP_6 = 6,
}

export interface DataStep1 {
  bsid: string;
  id: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  subscribe: boolean;
  profilingConsent: boolean;
  businessUseConsent: boolean;
  birthday: string;
  birthplace: string;
  province: string;
  linkedinProfile: string;
}

export interface DataStep2 {
  primarySession?: {
    slug: string;
    sessionId: number;
    productCode: string;
    category: string;
    courseTypeFull: string;
    courseTitle: string;
    price: number;
    discountPrice: number;
    lessonMode: string;
    brochureAvailable: boolean;
    brochureBooking: boolean;
    brochureUrl: string;
    courseUrl: string;
    courseStartDate: string;
    courseReferrerName: string;
    courseReferrerEmail: string;
  };
  secondarySession?: {
    slug: string;
    sessionId: string;
    productCode: string;
    category: string;
    courseTypeFull: string;
    courseTitle: string;
    price: number;
    discountPrice: number;
    lessonMode: string;
    brochureAvailable: boolean;
    brochureBooking: boolean;
    brochureUrl: string;
    courseUrl: string;
    courseStartDate: string;
    courseReferrerName: string;
    courseReferrerEmail: string;
  };
  chooseLater: boolean;
}

export interface DataStep3 {
  graduation: string;
  graduationAtInstitute: string;
  graduationVote: string;
  graduationYear: string;
  specialistGraduation: string;
  specialistAtInstitute: string;
  specialistThesisTitle: string;
  specialistVote: string;
  specialistStartYear: string;
  specialistEndYear: string;
  masterGraduation: string;
  masterAtInstitute: string;
  masterThesisTitle: string;
  masterVote: string;
  masterStartYear: string;
  masterEndYear: string;
}

export interface DataStep4 {
  lastExperienceStartDate: string;
  lastExperienceEndDate: string;
  lastExperienceRole: string;
  lastExperienceCompany: string;
  secondExperienceStartDate: string;
  secondExperienceEndDate: string;
  secondExperienceRole: string;
  secondExperienceCompany: string;
}

export interface DataStep5 {
  extraCurricularActivity: string;
  hobbyAndInterests: string;
}

export interface DataStep6 {
  knowIsee: string;
  iseeRange: string;
  economicPosition: string;
}


export interface ScholarshipWizard {
  /**
   * Has the wizard been started?
   */
  readonly hasStarted: Readable<boolean>;

  /**
   * The current step of the wizard
   */
  readonly currentStep: Readable<Steps>;

  /**
   * Does the wizard have a previous step?
   */
  readonly hasPreviousStep: Readable<boolean>;

  /**
   * Does the wizard have a next step?
   */
  readonly hasNextStep: Readable<boolean>;

  /**
   * Is the next button disabled?
   */
  readonly nextButtonDisabled: Readable<boolean>;

  /**
   * Is the previous button disabled?
   */
  readonly previousButtonDisabled: Readable<boolean>;

  /**
   * Set the "next" button status
   * @param active The status of the button to set
   */
  setNextButtonStatus(active: boolean): void;

  /**
   *  Set the "next" button status
   * @param active The status of the button to set
   */
  setPreviousButtonStatus(active: boolean): void;

  /**
   * Start the wizard, that means go to first step
   */
  start(): void;

  /**
   * Proceed to the next step of the wizard
   */
  goToNextStep(): void;

  /**
   * Go back to he previous step of the wizard
   */
  goToPreviousStep(): void;
}
